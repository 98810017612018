










import Vue from 'vue'

export default Vue.extend({
  data() {
    let url = ''
    return { url }
  },
  mounted() {
    try {
      // @ts-ignore
      this.url = `https://cdn.discordapp.com/avatars/${this.$auth.user.user.user.id}/${this.$auth.user.user.user.avatar}.png?size=128`
    }
    catch {
      this.url = 'https://cdn.discordapp.com/embed/avatars/0.png'
    }
  }
})
