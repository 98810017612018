//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import DefaultLayout from '~/layouts/default.vue'
import { titleCase } from '@/services/Utilities'

export default Vue.extend({
  components: {
    DefaultLayout,
  },
  middleware: 'DashboardAuthCheck',
})
