import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _64b2a0b6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _48eac344 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _60c293e6 = () => interopDefault(import('../pages/status.vue' /* webpackChunkName: "pages/status" */))
const _fe12b7ea = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _7b412f3e = () => interopDefault(import('../pages/dashboard/_id/index.vue' /* webpackChunkName: "pages/dashboard/_id/index" */))
const _3d37c9df = () => interopDefault(import('../pages/dashboard/_id/guild.vue' /* webpackChunkName: "pages/dashboard/_id/guild" */))
const _6da03add = () => interopDefault(import('../pages/dashboard/_id/tags.vue' /* webpackChunkName: "pages/dashboard/_id/tags" */))
const _03ff0c8e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _64b2a0b6,
    name: "login"
  }, {
    path: "/privacy",
    component: _48eac344,
    name: "privacy"
  }, {
    path: "/status",
    component: _60c293e6,
    name: "status"
  }, {
    path: "/support",
    component: _fe12b7ea,
    name: "support"
  }, {
    path: "/dashboard/:id",
    component: _7b412f3e,
    name: "dashboard-id"
  }, {
    path: "/dashboard/:id?/guild",
    component: _3d37c9df,
    name: "dashboard-id-guild"
  }, {
    path: "/dashboard/:id?/tags",
    component: _6da03add,
    name: "dashboard-id-tags"
  }, {
    path: "/",
    component: _03ff0c8e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
