import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Contributors: () => import('../../components/support/Contributors.vue' /* webpackChunkName: "components/contributors" */).then(c => wrapFunctional(c.default || c)),
  SponsorButton: () => import('../../components/support/SponsorButton.vue' /* webpackChunkName: "components/sponsor-button" */).then(c => wrapFunctional(c.default || c)),
  Stargazers: () => import('../../components/support/Stargazers.vue' /* webpackChunkName: "components/stargazers" */).then(c => wrapFunctional(c.default || c)),
  TagsTable: () => import('../../components/dashboard/TagsTable.vue' /* webpackChunkName: "components/tags-table" */).then(c => wrapFunctional(c.default || c)),
  DiscordLogo: () => import('../../components/DiscordLogo.vue' /* webpackChunkName: "components/discord-logo" */).then(c => wrapFunctional(c.default || c)),
  FeatureCard: () => import('../../components/FeatureCard.vue' /* webpackChunkName: "components/feature-card" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  GuildDropdown: () => import('../../components/GuildDropdown.vue' /* webpackChunkName: "components/guild-dropdown" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  UserDisplay: () => import('../../components/UserDisplay.vue' /* webpackChunkName: "components/user-display" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
