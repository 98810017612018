










































































































import Vue from 'vue'
import { Guild } from 'services/Models/Guild'

export default Vue.extend({
  props: {
    side: String,
    showicon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const userGuildsConfig: Array<Guild> = []
    const userGuildsAdd: Array<Guild> = []
    let activeGuild: Guild | null = null
    return {
      userGuildsConfig,
      userGuildsAdd,
      activeGuild,
    }
  },
  mounted() {
    if (this.$auth.loggedIn && this.$auth.strategy.name === 'local') {
      let userGuilds = (this.$auth.user?.guilds as Array<Guild>)
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((g) => ({ ...g, isHovered: false } as Guild))

      userGuilds.forEach((x) => this.$set(x, 'isHovered', false))

      this.userGuildsConfig = userGuilds.filter((x) => x.isAdded)
      this.userGuildsAdd = userGuilds.filter(
        (x) =>
          !x.isAdded &&
          ((x.permissions & 0x8) == 0x8 || (x.permissions & 0x02) == 0x02)
      )

      if (this.$route.params.id !== undefined) {
        //@ts-ignore this fails the type checker for some reason but it works fine
        this.activeGuild =
          this.userGuildsConfig.find((x) => x.id === this.$route.params.id) ??
          null
      }
    }
  },
})
