






































































import Vue from 'vue'

export default Vue.extend({
  methods: {
    async login() {
      await this.$auth.loginWith('discord')
    },
  },
})
